
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as login28bISVjLyVarqVZmX4WQNVk0FS2z5Ic9gwC3tbRajdkUMeta } from "/home/evelle-2023/pages/login2.vue?macro=true";
import { default as _91_46_46_46slug_93tMTR2_l03_cO4feV5Qtlb2v8oXLDpYxI1fDdyTvgzd0Meta } from "/home/evelle-2023/pages/[...slug].vue?macro=true";
import { default as rwjgnonoiwgronwrggTS1HF0X3IN_452oAzi_45ficYEDvHwBEZYT9RO_45u0hd4uDwMeta } from "/home/evelle-2023/pages/rwjgnonoiwgronwrgg.vue?macro=true";
export default [
  {
    name: "login2",
    path: "/login2",
    component: () => import("/home/evelle-2023/pages/login2.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/evelle-2023/pages/[...slug].vue")
  },
  {
    name: "rwjgnonoiwgronwrgg",
    path: "/rwjgnonoiwgronwrgg",
    component: () => import("/home/evelle-2023/pages/rwjgnonoiwgronwrgg.vue")
  }
]