import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/evelle-2023/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/evelle-2023/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/evelle-2023/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/evelle-2023/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/evelle-2023/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/evelle-2023/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/evelle-2023/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/home/evelle-2023/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/evelle-2023/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/evelle-2023/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68 from "/home/evelle-2023/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import fontawesome_JnKv9qFGGymEa8jchv4AtGKiPqz5KDyuOewtt9OIRng from "/home/evelle-2023/plugins/fontawesome.js";
import pageproperty_CimHzRmiIOtYSjaxRHs71kwQdB0_rXK9uDhJaHvuXxs from "/home/evelle-2023/plugins/pageproperty.ts";
import webmessages_EfDPTFgwHslq5EqGU11Uv2QmJe_K_scOjdgfiYJKZCI from "/home/evelle-2023/plugins/webmessages.ts";
import pageLayout_zMpEbkiFAESEC25n7uVbfA__F_S7xUPtDKnSn_Eg5H0 from "/home/evelle-2023/plugins/pageLayout.ts";
import seoObjCreator_DMzX1iGZSrTjTB9DKW2OFmHgwBf1BNq1nXknZ_S_uPw from "/home/evelle-2023/plugins/seoObjCreator.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68,
  fontawesome_JnKv9qFGGymEa8jchv4AtGKiPqz5KDyuOewtt9OIRng,
  pageproperty_CimHzRmiIOtYSjaxRHs71kwQdB0_rXK9uDhJaHvuXxs,
  webmessages_EfDPTFgwHslq5EqGU11Uv2QmJe_K_scOjdgfiYJKZCI,
  pageLayout_zMpEbkiFAESEC25n7uVbfA__F_S7xUPtDKnSn_Eg5H0,
  seoObjCreator_DMzX1iGZSrTjTB9DKW2OFmHgwBf1BNq1nXknZ_S_uPw
]